import { useVisibilityChange } from '@uidotdev/usehooks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import type { Auction } from 'storefront-client';
import { useDebouncedCallback } from 'use-debounce';

export const useFreshAuctionData = (auctions: Auction[] | undefined) => {
  const documentVisible = useVisibilityChange();
  const revalidator = useRevalidator();
  const [shouldRevalidate, setShouldRevalidate] = useState(false);

  const debouncedRevalidate = useDebouncedCallback(
    () => {
      revalidator.revalidate();
    },
    500,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    if (documentVisible && shouldRevalidate) {
      debouncedRevalidate();
      setShouldRevalidate(false);
    }
  }, [documentVisible, shouldRevalidate]);

  // loop through auctions to find when the next upcoming next_price_drop_date datetime is and revalidate that data at that time then set a timer until that time and revalidate the data when that timer hits
  useEffect(() => {
    if (!auctions) return;

    const nextPriceDropDate = auctions.reduce(
      (acc, auction) => {
        const nextPriceDropDate = DateTime.fromISO(auction.next_price_drop_date);
        if (!acc || nextPriceDropDate < acc) {
          return nextPriceDropDate;
        }
        return acc;
      },
      undefined as DateTime | undefined,
    );

    let timeout: ReturnType<typeof setTimeout>;

    if (nextPriceDropDate) {
      timeout = setTimeout(() => {
        setShouldRevalidate(true);
      }, nextPriceDropDate.diffNow('milliseconds').milliseconds);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [auctions]);
};
