import useAuctionData from '@storefront/ui-components/hooks/useAuctionData';
import type { FC } from 'react';
import type { Auction } from 'storefront-client';
import { Flags, useFlags } from '~/flags';
import { AuctionCardPresentation } from './AuctionCardPresentation';

export interface AuctionCardProps {
  className?: string;
  auction: Auction;
  action: {
    label: string;
    onClick?: () => void;
  };
  currency: Intl.NumberFormatOptions['currency'];
}

export const AuctionCard: FC<AuctionCardProps> = ({ className, currency, auction, action }) => {
  const {
    isGoodForPets,
    originalPrice,
    discountedPrice,
    priceDropTimeInMinutes,
    imageSrc,
    bidCount,
    inventoryCount,
    itemHasBeenSold,
    percentageDiscount,
    hasDiscountBiggerThan30Percent,
    auctionWillEndSoon,
  } = useAuctionData({ auction });

  const isBidCountVisible = useFlags(Flags.IS_BID_COUNT_VISIBLE);

  const showBidCount = isBidCountVisible && imageSrc && !itemHasBeenSold && bidCount >= 1;

  return (
    <AuctionCardPresentation
      className={className}
      currency={currency}
      title={auction.product.title}
      productCondition={auction.product.product_condition}
      isGoodForPets={isGoodForPets ?? false}
      originalPrice={originalPrice}
      discountedPrice={discountedPrice}
      priceDropTimeInMinutes={priceDropTimeInMinutes}
      imageSrc={imageSrc ?? null}
      bidCount={bidCount}
      inventoryCount={inventoryCount}
      itemHasBeenSold={itemHasBeenSold}
      percentageDiscount={percentageDiscount}
      hasDiscountBiggerThan30Percent={hasDiscountBiggerThan30Percent}
      auctionWillEndSoon={auctionWillEndSoon}
      showBidCount={Boolean(showBidCount)}
    />
  );
};
