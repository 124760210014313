import { NavLink, useNavigation } from '@remix-run/react';
import clsx from 'clsx';
import type { FC } from 'react';
import { ProductListHeader, type ProductListHeaderProps } from '../product/ProductListHeader';
import { AuctionCard } from '../shared/card/AuctionCard';

import { buildProductUrl } from '@storefront/util/auction/buildProductUrl';
import type { Auction } from 'storefront-client';
import { useSendEvent } from '../../util/analytics/useAnalytics';
import { useRegion } from '../hooks/useRegion';

export interface AuctionListProps extends Partial<ProductListHeaderProps> {
  auctions?: Auction[];
  className?: string;
}

export const AuctionGrid: FC<AuctionListProps> = ({
  heading,
  auctions,
  className = 'grid grid-cols-2 gap-y-6 @lg:grid-cols-3 gap-x-4 @4xl:!grid-cols-5 @4xl:gap-x-4 @6xl:gap-x-8 @6xl:!grid-cols-5',
}) => {
  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';
  const { region } = useRegion();
  const currency_code = region.currency_code;
  const sendNavigationEvent = useSendEvent('navigation');

  return (
    <div
      className={clsx('@container', {
        'animate-pulse': isLoading,
      })}
    >
      <ProductListHeader heading={heading} />

      <div className={className}>
        {auctions?.map((auction) => (
          <NavLink
            id="auction-card"
            prefetch="intent"
            key={auction.id}
            to={buildProductUrl(auction.product.handle)}
            viewTransition
            className={`${auction.status === 'COMPLETED' ? 'cursor-not-allowed pointer-events-none' : ''}`}
            onClick={() =>
              sendNavigationEvent({
                type: 'Auction Card Clicked',
                destination: buildProductUrl(auction.product.handle),
              })
            }
          >
            {({
              // TODO: Add transitions between auction card and auction page
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              isTransitioning,
            }) => (
              <AuctionCard
                currency={currency_code}
                auction={auction}
                action={{
                  label: 'Bid or Buy Now',
                  onClick: () => {
                    // Do nothing
                  },
                }}
              />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

// required for lazy loading this component
export default AuctionGrid;
