import { ClientOnly } from 'remix-utils/client-only';
import type { Auction } from 'storefront-client';
import { useFreshAuctionData } from '../hooks/useFreshAuctionData';

export const RevalidateAuctions = ({ auctions }: { auctions: Auction[] | undefined }) => {
  return (
    <ClientOnly>
      {() => {
        return <FreshAuctionData auctions={auctions} />;
      }}
    </ClientOnly>
  );
};

const FreshAuctionData = ({ auctions }: { auctions: Auction[] | undefined }) => {
  useFreshAuctionData(auctions);

  return null;
};
